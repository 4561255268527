import * as React from 'react'
import { graphql } from 'gatsby'
import './index.scss'
import Header from '../../components/header/header'
import WithHeaderLayour from '../../layouts/with-header'
import Footer from '../../components/footer/footer'
import PageHeader from '../../components/page-header/page-header'
import SeloAbracam from '../../components/selo-abracam/selo-abracam'

const IndexPage = ({ data, location }) => {
  const nomeDaEmpresa = data.contentfulGeral.nomeDaEmpresa
  const telefone = data.contentfulGeral.telefone

  return (
    <WithHeaderLayour>
      <Header currentPath={location?.pathname} />
      <PageHeader
        headline={`Seguro Viagem`}
        description="O Seguro-Viagem Internacional JMS é a melhor maneira de assegurar que tudo saia como planejado — incluindo o que não pode ser previsto."
      />
      <div className="container" style={{ padding: '70px 0' }}>
        <h2>Invista em sua tranquilidade</h2>
        <div>
          Sabemos que viajar, especialmente para o exterior, requer planejamento
          para que tudo corra sem sobressaltos ou imprevistos. Ainda assim,
          todos estamos sujeitos a situações inesperadas que podem afetar nosso
          lazer durante a viagem ou até mesmo ela como um todo.
          <br />
          <br />
          Tendo em mente esses cenários, a JMS provê um seguro-viagem para
          oferecer-lhe proteção durante suas idas ao exterior, assegurando que
          tenha milhares de profissionais e instituições médicas à sua
          disposição a qualquer hora do dia por todo o globo.
        </div>
        <h2>A melhor cobertura</h2>
        <div>
          <ul>
            <li>Mais de 35 anos de história</li>
            <li>Mais de 150 países atendidos</li>
            <li>Mais de 100 milhões de passageiros cobertos</li>
            <li>Mais de 1000 colaboradores em todo o mundo</li>
          </ul>
        </div>
        <h2>Alguns dos serviços contemplados</h2>
        <div>
          <ul>
            <li>Assistência Médica para Doenças e Acidentes</li>
            <li>Assistência Odontológica</li>
            <li>Assistência Farmacêutica</li>
            <li>Repatriação Sanitária</li>
            <li>Seguro Bagagem</li>
            <li>Seguro de Vida e Invalidez</li>
          </ul>
        </div>
      </div>

      <SeloAbracam />
      <Footer />
    </WithHeaderLayour>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query SeguroViagemQuery {
    contentfulGeral {
      nomeDaEmpresa
      telefone
    }
  }
`
